'use client';

import { ExclamationTriangleIcon } from '@radix-ui/react-icons';

import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { Button } from '@/app/_components/ui/button';
import clientConfig from '@/lib/config/client-config';

interface ErrorProps {
  error: Error & { digest?: string };
  reset: () => void;
}

const ErrorDetails = ({ error }: Pick<ErrorProps, 'error'>) => {
  const [seeDetails, setSeeDetails] = useState<boolean>(true);
  const t = useTranslations();

  return (
    <>
      <Button
        size="sm"
        variant="outline"
        onClick={() => setSeeDetails(!seeDetails)}
      >
        {t(
          seeDetails ? 'errorBoundary.hideDetails' : 'errorBoundary.seeDetails',
        )}
      </Button>

      {seeDetails ? (
        <div className="w-3/4 overflow-auto rounded-md border border-destructive p-4 text-xs text-destructive">
          <pre>{error.stack}</pre>
        </div>
      ) : null}
    </>
  );
};

const Error = ({ reset, error }: ErrorProps) => {
  const t = useTranslations();

  return (
    <div className="h-screen">
      <div className="flex size-full flex-col items-center justify-center gap-4">
        <ExclamationTriangleIcon className="size-10 text-destructive" />
        <h2 className="font-heading text-xl text-destructive">
          {t('error.errorOccured')}
        </h2>

        <Button onClick={() => reset()}>{t('errorBoundary.tryAgain')}</Button>

        {!clientConfig.isDeployedEnv ? <ErrorDetails error={error} /> : null}
      </div>
    </div>
  );
};

export default Error;
